<template>
  <modal :modalId="`modal_discard`" :modalTitle="discardTitle" :load="false" :typeLg="'modal_wd'" @closeModal="closeModal">
    <div class="containerBody">
      <div>
        <div class="containers">
          <input
            class="radios"
            value="1"
            v-model="toggle"
            type="radio"
            id="yes"
          />
          <label class="label" for="yes">Sim!</label>
        </div>
        <div class="containers">
          <input
            class="radios"
            value="0"
            v-model="toggle"
            type="radio"
            id="no"
          />
          <label class="label" for="no">Não! Voltar</label>
        </div>
      </div>
      <transition name="fadesTransition">
        <section v-if="toggle == 1">
          <form v-for="item in options" :key="item.id">
            <input
              class="radios"
              type="radio"
              :value="item.value"
              v-model="newValue"
              :id="item.id"
            />
            <label class="label" :for="item.id">{{ item.value }}</label>
          </form>
        </section>
      </transition>

      <transition name="fadesTransition">
        <div class="justificar" v-if="newValue == 'Outro' && toggle == 1">
          <textarea
            placeholder="Justificativa..."
            class="text"
            name=""
            id=""
            cols="30"
            rows="10"
            maxlength="100"
            v-model='text'
          >
          </textarea>
          <p>máximo de {{contador}} caracteres </p>
        </div>
      </transition>
      <div class="modal-footer containerFooter">
        <button type="button" class="btn_default" @click="send" :disabled="load">
          <half-circle-spinner v-if="load" :animation-duration="1000" :size="30" color="#fff"/>
          <strong v-else>
             Enviar
          </strong>
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import HTTP from "@/api_system";
import modal from "@/components/modal/modalDefault.vue";
import { HalfCircleSpinner } from "epic-spinners";
import { mapGetters } from 'vuex'
export default {
  components: {
    modal,
    HalfCircleSpinner
  },
  data() {
    return {
      options: [
        {
          id: 1,
          value: "Cliente desistiu da compra",
        },
        {
          id: 2,
          value: "Cliente não tem recursos para pagar",
        },
        {
          id: 3,
          value: "Serviço(s) adicionado(s) equivocadamente",
        },
        {
          id: 4,
          value: "Serviço não concluído com sucesso",
        },
        {
          id: 5,
          value: "Outro",
        },
      ],
      newValue: undefined,
      text: undefined,
      toggle: undefined,
      contador: 100,
      valuesContador: 0,
      load: false
    };
  },
  computed: {
    ...mapGetters(["getDiscard"]),
    discardTitle() {
      return this.getDiscard && this.getDiscard.title ? this.getDiscard.title : ''; 
    }
  },
  watch: {
    'text': function(value) {
      if (value.length > this.valuesContador) {
        this.contador = this.contador - 1
        this.valuesContador = value.length
      } else if (value.length < this.valuesContador) {
        this.contador = this.contador + 1
        this.valuesContador = value.length
      }
    },
  },
  methods: {
    closeModal(){
      $('#modal_discard').modal('hide');
    },
    send() {
      const { toggle, newValue, text } = this

      if (toggle == 0) {
        $("#modal_discard").modal("hide");
        this.newValue = undefined
        return
      }

      if(newValue === 'Outro') {
        if(!text) {
          this.toast('Justifique o seu discarte!', this.$toast.error)
          return
        }
        this.deletePedidos(text)
        return
      }

      if(newValue === undefined) {
        this.toast('Selecione uma das alternativas!', this.$toast.error)
        return
      }
      this.deletePedidos(newValue)
    },
    deletePedidos(message) {
      this.load = true

      HTTP.post(`${this.getDiscard.url}=${message}`,	"",
        {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        }
		  )
			.then(response => {
        this.toast(this.getDiscard.service ? 'Serviço Cancelado com sucesso' : 'Pedido Cancelado!' , this.$toast)
        $("#modal_discard").modal("hide");
        this.newValue = undefined
        this.load = false
        this.$store.commit("payValue", '')
        if(this.getDiscard.service) {
          this.$store.commit("set_pedido", response.data.result);
          return
        }

        this.$store.commit("changeOrderId", '')
				setTimeout(() => {
          this.$store.commit("set_pedido", "");
					this.$router.push("/pedidos");
				}, 2000);
			})
			.catch(error => {
        console.error(error)
        $("#modal_discard").modal("hide");
        if(error.response.data.error.code === 302) {
          this.toast(error.response.data.error.message, this.$toast.error)
          this.redirectPageError()
          return
        }

        if(error.response.data.error.code === 100) {
          this.toast(error.response.data.error.message, this.$toast.error)
          this.redirectPageError()
          return
        }
        this.toast('Ops algo deu errado!', this.$toast.error)
			});
    },
    redirectPageError() {
      this.$store.commit("changeOrderId", "");
      setTimeout(() => {
        this.$store.commit("set_pedido", "");
        this.$router.push("/pedidos");
      }, 2000);
    },
  },
};
</script>

<style scoped>


.containerBody {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.containerBody section {
  width: 70%;
}

.containerBody > div {
  display: flex;
  justify-content: space-evenly;
  width: 70%;
  align-items: center;
  margin: 20px;
}

.containerBody > div input {
  margin: 0 10px;
}

.containerBody > div label {
  margin: 0;
}

.btn_default {
  background: #F38235;
  border-radius: 6px;
  border: none;
  color: #fff;
  height: 40px;
  width: 162px;
  padding: 0 20px;
  border: none;
  transition: all 0.2s linear;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn_default:hover {
  background-color: #e0a638;
}

form {
  display: flex;
  align-items: center;
  margin: 0 0 10px;
}

.label {
  margin-bottom: 0;
  font-size: 18px;
  color: #8a7e76;
  cursor: pointer;
  font-weight: 700;
}

.radios {
  width: 40px;
  height: 22px;
}

.text {
  width: 70%;
  border-radius: 5px;
  padding: 20px;
}

.justificar {
  display: flex;
  justify-content: initial;
  flex-direction: column;
  margin-bottom: 0;
}

.justificar textarea {
  width: 100%;
  margin-bottom: 10px;
}

.containerFooter {
  margin: 0 !important;
}

.containers {
  display: flex;
  align-items: center;
}

.fadesTransition-enter-active,
.fadesTransition-leave-active {
  transition: opacity 0.6s;
}
.fadesTransition-enter, .fadesTransition-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
